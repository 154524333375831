/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request: h5bp.com/r
   ========================================================================== */

$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

* {
  background: transparent;
  //color: #000 !important; /* Black prints faster: h5bp.com/s */
  box-shadow: none !important;
  text-shadow: none !important;
}

a,
a:visited {
  text-decoration: none;
}

/*
 * Don't show links that are fragment identifiers,
 * or use the `javascript:` pseudo protocol
 */

a[href^='#']:after,
a[href^='/']:after,
a[href^='javascript:']:after {
  content: '';
}

pre,
blockquote {
  border: 1px solid #999;
  page-break-inside: avoid;
}

thead {
  display: table-header-group; /* h5bp.com/t */
}

tr,
img {
  page-break-inside: avoid;
}

img {
  max-width: 100% !important;
}

p,
h2,
h3 {
  orphans: 3;
  widows: 3;
}

h2,
h3 {
  page-break-after: avoid;
}

.site-header {
  display: none;
}

.site-footer {
  display: none;
}

///
// Language & Locale choosers
///
.locale-select,
.language-select {
  display: block;
}

///
// Country Chooser
///
.country-select {
  &__selectbox {
    width: 200px;
  }
}

///
// Pending PRO PAGE
///

.account {
  &__section {
    @include swap_direction(margin, 20px auto);
    &.pg_wrapper {
      padding-top: 0px;
    }
  }
}

.pending-pro {
  .btns,
  .go_account {
    display: none;
  }
  &__header {
    font-family: $brandon-text-bold;
    font-size: 18px;
    text-indent: 20px;
    @include swap_direction(margin, 20px 0 17px 0);
    padding-bottom: 16px;
    border-bottom: 2px solid $color-black;
    &:before {
      background: none;
    }
  }
  &__required-container {
    top: 212px;
    #{$rdirection}: 305px;
  }
  .profile-info {
    &__item {
      @include swap_direction(margin, 0 20px 20px);
      width: 346px;
    }
  }
  .optional-info {
    &__item {
      float: none;
      &.birthday {
        &-date {
          @include swap_direction(padding, 0);
        }
      }
    }
    &__birthday {
      &-select {
        width: 12%;
        min-width: 12%;
        display: inline-block;
        @include swap_direction(margin, 0 10px 20px 20px);
      }
    }
  }
  .address-form {
    &__item {
      float: $ldirection;
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0 20px 20px);
      width: 346px;
      &.state,
      &.postal-code {
        margin-#{$rdirection}: 12px;
        width: 14%;
      }
    }
  }
  .pro-criteria {
    &-level {
      &__column_left {
        width: 47.5%;
      }
      &__column_right {
        width: 50%;
      }
      ul {
        li {
          width: 82%;
        }
      }
    }
  }
  &_thanks {
    @include swap_direction(margin, 0 0 20px 20px);
  }
  &_registration_steps,
  &_print,
  &_level,
  &_mail_processing {
    padding-#{$ldirection}: 40px;
  }
  &_steps,
  &_mail_address {
    float: $ldirection;
    padding-#{$rdirection}: 20px;
    width: 50%;
  }
  .user-info {
    &__item {
      float: $ldirection;
      width: 50%;
      padding-#{$ldirection}: 20px;
    }
  }
}
